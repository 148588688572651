import React, { useState } from "react";
import styled from "styled-components";
import ScoreDisplay from "./ScoreDisplay";
import HandNumberDisplay from "./HandNumberDisplay";
import { ScoreModal, ScoreModalProps } from "./ScoreModal";

interface HandAndScoreDisplayProps {
    nsScore: number;
    ewScore: number;
    handNumber: number;
    scoreModalProps: Omit<ScoreModalProps, "isOpen" | "setIsOpen">;
}

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`;

const HandAndScoreDisplay: React.FC<HandAndScoreDisplayProps> = ({ nsScore, ewScore, handNumber, scoreModalProps }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <Container onClick={handleClick}>
                <ScoreDisplay nsScore={nsScore} ewScore={ewScore} />
                <HandNumberDisplay handNumber={handNumber} />
            </Container>
            <ScoreModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} {...scoreModalProps} />
        </>
    );
};

export default HandAndScoreDisplay;
