import React from "react";
import styled from "styled-components";
import theme from "../theme";

interface SwitchProps {
    leftOption: string;
    rightOption: string;
    value: boolean;
    onChange: (value: boolean) => void;
    leftColor?: string;
    rightColor?: string;
    leftTextColor?: string;
    rightTextColor?: string;
}

const SwitchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SwitchButton = styled.button<{ $isActive: boolean; $activeColor: string; $activeTextColor: string; $isRight: boolean }>`
    flex: 1;
    height: ${(props) => (props.$isActive ? "38px" : "30px;")};
    background-color: ${(props) => (props.$isActive ? props.$activeColor : theme.colors.light)};
    color: ${(props) => (props.$isActive ? props.$activeTextColor : theme.colors.black_text)};
    border: 1px solid #ccc;
    border-radius: ${(props) => (props.$isActive ? "5px" : props.$isRight ? "0 5px 5px 0" : "5px 0 0 5px")};
    cursor: pointer;
    transition: all 0.3s;
    white-space: nowrap;
    padding: 0 10px;

    &:hover {
        background-color: ${(props) => (props.$isActive ? props.$activeColor : "#ccc")};
    }
`;

const Switch: React.FC<SwitchProps> = ({
    leftOption,
    rightOption,
    value,
    onChange,
    leftColor = theme.colors.wish_accent,
    rightColor = theme.colors.wish_accent,
    leftTextColor = theme.colors.white_text,
    rightTextColor = theme.colors.white_text,
}) => {
    return (
        <SwitchContainer>
            <SwitchButton
                $isActive={!value}
                $activeColor={leftColor}
                $activeTextColor={leftTextColor}
                $isRight={false}
                onClick={() => onChange(false)}
            >
                {leftOption}
            </SwitchButton>
            <SwitchButton
                $isActive={value}
                $activeColor={rightColor}
                $activeTextColor={rightTextColor}
                $isRight={true}
                onClick={() => onChange(true)}
            >
                {rightOption}
            </SwitchButton>
        </SwitchContainer>
    );
};

export default Switch;
