import React from "react";
import styled from "styled-components";
import theme from "../../theme";

const Container = styled.div`
    background-color: ${theme.colors.primary_very_light};
    border-radius: 5px;
    padding: 10px;
    margin: 12px;
    height: 60px;
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 5px;
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

interface ConvenienceOptionsPanelProps {
    autopassNoCards: boolean;
    setAutopassNoCards: (value: boolean) => void;
    autopassCantPlay: boolean;
    setAutopassCantPlay: (value: boolean) => void;
}

export const ConvenienceOptionsPanel: React.FC<ConvenienceOptionsPanelProps> = ({
    autopassNoCards,
    setAutopassNoCards,
    autopassCantPlay,
    setAutopassCantPlay,
}) => {
    return (
        <Container>
            <CheckboxLabel>
                <StyledCheckbox
                    checked={autopassNoCards}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAutopassNoCards(e.target.checked)}
                    disabled={autopassCantPlay}
                />
                Autopass when obvious
            </CheckboxLabel>
            <CheckboxLabel>
                <StyledCheckbox
                    checked={autopassCantPlay}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAutopassCantPlay(e.target.checked)}
                    disabled={true}
                />
                Autopass anytime you can't play
            </CheckboxLabel>
        </Container>
    );
};
