import React from "react";
import styled from "styled-components";
import theme from "../../theme";

interface ScoreDisplayProps {
    nsScore: number;
    ewScore: number;
}

const ScoreContainer = styled.div`
    position: relative;
    width: 120px;
    height: 36px;
    border-radius: 6px;
    overflow: hidden;
`;

const ScoreBackground = styled.div<{ bgColor: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.bgColor};
`;

const NSBackground = styled(ScoreBackground)`
    clip-path: polygon(0 0, 100% 0, calc(50% - 3.5px) 100%, 0 100%);
`;

const EWBackground = styled(ScoreBackground)`
    clip-path: polygon(calc(50% - 3.5px) 100%, 100% 100%, 100% 0, calc(50% + 3.5px) 0);
`;

const ScoreText = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    color: ${theme.colors.white_text};
    font-weight: bold;
`;

const NSText = styled(ScoreText)`
    left: 0;
    width: 50%;
    justify-content: center;
`;

const EWText = styled(ScoreText)`
    right: 0;
    width: 50%;
    justify-content: center;
`;

const ScoreDisplay: React.FC<ScoreDisplayProps> = ({ nsScore, ewScore }) => {
    return (
        <ScoreContainer>
            <NSBackground bgColor={theme.colors.ns_color} />
            <EWBackground bgColor={theme.colors.ew_color} />
            <NSText>{nsScore}</NSText>
            <EWText>{ewScore}</EWText>
        </ScoreContainer>
    );
};

export default ScoreDisplay;
