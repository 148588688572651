import axios from "axios";
import {
    DmAuthResponse,
    DmGameHand,
    DmMove,
    DmTable,
    DmTableSettings,
    DmTradeSlateChoice,
    DmUser,
    DmUserSettings,
    DmOmniscientAiAssessment,
    DmBlindAiAssessment,
} from "./server-types-python";

export class ApiClient {
    baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    async register(email: string, password: string, displayName: string, avatar: number): Promise<DmAuthResponse | Error> {
        try {
            const { data, status } = await axios.post<DmAuthResponse>(
                this.baseUrl + "/api/auth/register",
                {
                    email: email,
                    password: password,
                    display_name: displayName,
                    avatar: avatar,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            console.log(JSON.stringify(data, null, 4));
            console.log("response status is: ", status);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async login(email: string, password: string): Promise<DmAuthResponse | Error> {
        try {
            const { data, status } = await axios.post<DmAuthResponse>(
                this.baseUrl + "/api/auth/login",
                {
                    email: email,
                    password: password,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            console.log(JSON.stringify(data, null, 4));
            console.log("response status is: ", status);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async resetPassword(email: string): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<void>(
                this.baseUrl + "/api/auth/reset_password",
                {
                    email: email,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async changePasswordViaToken(email: string, newPassword: string, token: string): Promise<DmAuthResponse | Error> {
        try {
            const { data, status } = await axios.post<DmAuthResponse>(
                this.baseUrl + "/api/auth/change_password_via_token",
                {
                    email: email,
                    new_password: newPassword,
                    token: token,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            console.log("response status is: ", status);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async verifyToken(): Promise<DmUser | Error> {
        try {
            const { data, status } = await axios.get<DmUser>(this.baseUrl + "/api/auth/verify", {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            });

            console.log("response status is: ", status);
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async updateSettings(displayName: string, avatar: number, userSettings: DmUserSettings): Promise<{ user: DmUser } | Error> {
        try {
            const { data, status } = await axios.post<{ user: DmUser }>(
                this.baseUrl + "/api/auth/update_settings",
                {
                    display_name: displayName,
                    avatar: avatar,
                    settings: userSettings,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async getTable(tableId: string): Promise<DmTable | Error> {
        try {
            const { data, status } = await axios.get<DmTable>(this.baseUrl + "/api/table/" + tableId, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            });

            console.log(JSON.stringify(data, null, 4));
            console.log("response status is: ", status);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async getGameHand(tableId: string, handIndex: number): Promise<DmGameHand | Error> {
        try {
            const { data, status } = await axios.get<DmGameHand>(this.baseUrl + "/api/table/" + tableId + "/gameHand/" + handIndex, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            });

            console.log(JSON.stringify(data, null, 4));
            console.log("response status is: ", status);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async listTables(): Promise<DmTable[] | Error> {
        try {
            const { data, status } = await axios.get<DmTable[]>(this.baseUrl + "/api/table/active", {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            });

            console.log(JSON.stringify(data, null, 4));
            console.log("response status is: ", status);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async newTable(): Promise<DmTable | Error> {
        try {
            const { data, status } = await axios.post<DmTable>(
                this.baseUrl + "/api/table/new",
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log(JSON.stringify(data, null, 4));
            console.log("response status is: ", status);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async newGame(tableId: string): Promise<DmTable | Error> {
        try {
            const { data, status } = await axios.post<DmTable>(
                this.baseUrl + "/api/table/" + tableId + "/new_game",
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log(JSON.stringify(data, null, 4));
            console.log("response status is: ", status);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async updateTableSettings(tableId: string, settings: Partial<DmTableSettings>): Promise<DmTable | Error> {
        try {
            const { data, status } = await axios.post<DmTable>(`${this.baseUrl}/api/table/${tableId}/update_settings`, settings, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            });

            console.log("response status is: ", status);
            console.log(JSON.stringify(data, null, 4));

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async joinTable(tableId: string): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<DmTable>(
                this.baseUrl + "/api/table/" + tableId + "/join",
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async leaveTable(tableId: string): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<void>(
                this.baseUrl + "/api/table/" + tableId + "/leave",
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async requestUndo(tableId: string, handIndex: number, moveIndex: number): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<void>(
                this.baseUrl + "/api/table/" + tableId + "/request_undo",
                {
                    hand_index: handIndex,
                    move_index: moveIndex,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async requestAbort(tableId: string): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<void>(
                this.baseUrl + "/api/table/" + tableId + "/request_abort",
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async approveRequest(tableId: string, requestId: string): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<void>(
                this.baseUrl + "/api/table/" + tableId + "/approve_request/" + requestId,
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async denyRequest(tableId: string, requestId: string): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<void>(
                this.baseUrl + "/api/table/" + tableId + "/deny_request/" + requestId,
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async addBot(tableId: string, seat: number): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<DmTable>(
                `${this.baseUrl}/api/table/${tableId}/addBot`,
                { seat: seat },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async removeBot(tableId: string, seat: number): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<DmTable>(
                `${this.baseUrl}/api/table/${tableId}/removeBot`,
                { seat: seat },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async changeSeat(tableId: string, seat: number): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<DmTable>(
                `${this.baseUrl}/api/table/${tableId}/changeSeat`,
                { seat: seat },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async startTable(tableId: string): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<DmTable>(
                this.baseUrl + "/api/table/" + tableId + "/start",
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );
            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async makeMove(tableId: string, move: DmMove) {
        try {
            const { status } = await axios.post<DmMove>(this.baseUrl + "/api/table/" + tableId + "/act", move, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            });

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async chooseTrade(tableId: string, trade: DmTradeSlateChoice) {
        try {
            const { status } = await axios.post<DmTradeSlateChoice>(this.baseUrl + "/api/table/" + tableId + "/choose_trade", trade, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            });

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async chooseGrandBet(tableId: string, seat: number, choice: boolean): Promise<undefined | Error> {
        try {
            const { status } = await axios.post<void>(
                `${this.baseUrl}/api/table/${tableId}/choose_grand_bet`,
                {
                    position: seat,
                    bet: choice,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async nextHand(tableId: string) {
        try {
            const { status } = await axios.post<DmMove>(
                this.baseUrl + "/api/table/" + tableId + "/nextHand",
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async getOmniscientAiAssessment(tableId: string, handIndex: number, moveIndex: number): Promise<DmOmniscientAiAssessment | Error> {
        try {
            const { data, status } = await axios.post<DmOmniscientAiAssessment>(
                `${this.baseUrl}/api/table/${tableId}/getOmniscientAssessment`,
                {
                    hand_index: handIndex,
                    move_index: moveIndex,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
            console.log(JSON.stringify(data, null, 4));

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }

    async getBlindAiAssessment(tableId: string, handIndex: number, moveIndex: number): Promise<DmBlindAiAssessment | Error> {
        try {
            const { data, status } = await axios.post<DmBlindAiAssessment>(
                `${this.baseUrl}/api/table/${tableId}/getBlindAssessment`,
                {
                    hand_index: handIndex,
                    move_index: moveIndex,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                }
            );

            console.log("response status is: ", status);
            console.log(JSON.stringify(data, null, 4));

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error;
            } else if (error instanceof Error) {
                console.log("unexpected error: ", error);
                return error;
            } else {
                console.log("super unexpected error: ", error);
                return new Error("unexpected error");
            }
        }
    }
}
