const theme = {
    colors: {
        primary: "#2f8cb6",
        primary_dark: "#2a7ca2",
        primary_darker: "#1a6c92",
        primary_light: "#5dafd5",
        primary_medium: "#349bcb",
        primary_dull: "#6a8997",
        primary_very_light: "#9ecfe6",
        replay_mode_light: "#cfcfcf",
        replay_mode_dark: "#7a7a7a",
        alternate: "#4040bf",
        alternate_light: "#6666cc",
        alternate_dark: "#333399",
        alternate_very_light: "#b5b5e3",
        alternate_dull: "#60609f",
        light: "##f1f1f1",
        white_text: "#f1f1f1",
        white_text_dull: "#ced4da",
        black_text: "#080808",
        black_text_disabled: "#666666",
        primary_hinted_white: "#d6ebf5",
        alternate_hinted_white: "#dadaf1",
        wish_accent: "#a054cc",
        score_header: "#ba4d12",
        score_header_light: "#e86017",
        action: "#FFD700",
        action_hover: "#ffe657",
        action_disabled: "#cccccc",
        action_positive: "#33aa33",
        action_positive_hover: "#55cc55",
        action_negative: "#dd3333",
        action_negative_hover: "#ff5555",
        ns_color: "#4040bf",
        ns_color_light: "#b5b5e3",
        ew_color: "#ba4d12",
        ew_color_light: "#f3a17a",
    },
};

export default theme;
