import React, { useState } from "react";
import styled from "styled-components";
import { ApiClient } from "../../client/ApiClient";
import { DmBlindAiAssessment, DmPlayState } from "../../client/server-types-python";
import { NowplayState, ReplayState } from "../../model/ReplayState";
import {
    getAiAssessmentSortedActions,
    getAiAssessmentSortedChildStates,
    getAiAssessmentSortedTreeSearchValueEstimates,
    getAiAssessmentSortedTreeSearchVisitCounts,
} from "../../model/AiAssessmentUtil";
import theme from "../../theme";

interface AiAssessmentPanelProps {
    apiClient: ApiClient;
    tableId: string;
    replayState: ReplayState;
    nowplayState: NowplayState;
    playState: DmPlayState;
}

const PanelContainer = styled.div<{ isNSTurn: boolean }>`
    width: 250px;
    height: 530px;
    background-color: ${(props) => (props.isNSTurn ? theme.colors.ns_color_light : theme.colors.ew_color_light)};
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;
`;

const AssessmentButton = styled.button<{ isNSTurn: boolean }>`
    background-color: ${(props) => (props.isNSTurn ? theme.colors.ns_color : theme.colors.ew_color)};
    color: ${theme.colors.white_text};
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;

    &:hover {
        background-color: ${(props) => (props.isNSTurn ? theme.colors.ns_color_light : theme.colors.ew_color_light)};
        color: ${theme.colors.black_text};
    }

    &:disabled {
        background-color: ${theme.colors.primary_dull};
        color: ${theme.colors.white_text_dull};
        cursor: not-allowed;
    }
`;

const LoadingMessage = styled.div`
    color: ${theme.colors.black_text};
    font-style: italic;
    margin-top: 10px;
`;

const ErrorMessage = styled.div`
    color: ${theme.colors.action_negative};
    font-weight: bold;
    margin-top: 10px;
`;

const AssessmentResults = styled.div`
    color: ${theme.colors.black_text};
    font-size: 12px;

    h3 {
        margin-top: 0;
        color: ${theme.colors.black_text};
    }

    p {
        margin: 5px 0;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 10px;
    }

    th,
    td {
        border: 1px solid black;
        padding: 5px;
        text-align: left;
    }

    th {
        font-weight: bold;
    }
`;

export const AiAssessmentPanel: React.FC<AiAssessmentPanelProps> = ({ apiClient, tableId, replayState, nowplayState, playState }) => {
    const [assessment, setAssessment] = useState<DmBlindAiAssessment | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const unassessableState = playState.is_trade_phase || playState.is_hand_over;

    let handIndex: number, moveIndex: number;
    if (replayState.replayModeEnabled) {
        handIndex = replayState.replayHandCursor;
        moveIndex = replayState.replayMoveCursor;
    } else {
        handIndex = nowplayState.nowplayHandCursor;
        moveIndex = nowplayState.nowplayMoveCursor;
    }

    const handleGetAssessment = async () => {
        setLoading(true);
        setError(null);

        try {
            const result = await apiClient.getBlindAiAssessment(tableId, handIndex, moveIndex);
            if (result instanceof Error) {
                throw result;
            }
            setAssessment(result);
        } catch (err) {
            setError(err instanceof Error ? err.message : "An unknown error occurred");
        } finally {
            setLoading(false);
        }
    };

    const sortedActions = assessment ? getAiAssessmentSortedActions(assessment) : [];
    const topSortedActions = sortedActions.slice(0, 5);
    const sortedChildStates = assessment ? getAiAssessmentSortedChildStates(assessment) : [];
    const topSortedChildStates = sortedChildStates.slice(0, 5);

    const sortedTreeSearchVisitCounts = assessment ? getAiAssessmentSortedTreeSearchVisitCounts(assessment) : [];
    const topSortedTreeSearchVisitCounts = sortedTreeSearchVisitCounts.slice(0, 5);

    const sortedTreeSearchValueEstimates = assessment ? getAiAssessmentSortedTreeSearchValueEstimates(assessment) : [];
    const topSortedTreeSearchValueEstimates = sortedTreeSearchValueEstimates.slice(0, 5);
    const isNSTurn = playState.active_player % 2 === 0;

    const filteredTopSortedActions = topSortedActions.filter((action) => Number((action.count * 100).toFixed(1)) !== 0);

    return (
        <PanelContainer isNSTurn={isNSTurn}>
            <AssessmentButton onClick={handleGetAssessment} disabled={loading || unassessableState} isNSTurn={isNSTurn}>
                AI Assessment
            </AssessmentButton>

            {loading && <LoadingMessage>Loading...</LoadingMessage>}

            {error && <ErrorMessage>Error: {error}</ErrorMessage>}

            {assessment && (
                <AssessmentResults>
                    <p>Game EV: {assessment.state_value.toFixed(1)}</p>

                    <h4>Top Moves:</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Move</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTopSortedActions.map((action) => (
                                <tr key={action.move}>
                                    <td>{action.move}</td>
                                    <td>{(action.count * 100).toFixed(1)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h4>Top Child States:</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Move</th>
                                <th>Value +/-</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topSortedChildStates.map((childState) => (
                                <tr key={childState.move}>
                                    <td>{childState.move}</td>
                                    <td>{childState.value.toFixed(1)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h4>Top Tree Search Visits:</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Move</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topSortedTreeSearchVisitCounts.map((visit) => (
                                <tr key={visit.move}>
                                    <td>{visit.move}</td>
                                    <td>{visit.count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h4>Top Tree Search Value Estimates:</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Move</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topSortedTreeSearchValueEstimates.map((valueEstimate) => (
                                <tr key={valueEstimate.move}>
                                    <td>{valueEstimate.move}</td>
                                    <td>{valueEstimate.value.toFixed(1)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </AssessmentResults>
            )}
        </PanelContainer>
    );
};
