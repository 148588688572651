import styled from "styled-components";
import { ApiClient } from "../client/ApiClient";
import { DmUser } from "../client/server-types-python";
import { NavBar } from "../components/NavBar";
import React from "react";
import { getUserSettingSpeedLabel, getUserSettingVolumeLabel } from "../client/util";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAvatarUrl, getNextAvatarId, getPreviousAvatarId } from "../misc/Avatars";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/arrow-right.svg";

const ParentDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
`;

const SettingsArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;

    width: 500px;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
    padding: 20px;
    margin: 20px;
`;

const SettingsTopStrip = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    align-content: center;
    width: 100%;
    margin-bottom: 20px;
`;

const SettingsTable = styled.table`
    font-size: 16px;
`;

const SettingsItemRow = styled.tr`
    margin: 4px;
`;

const SettingsExplanationRow = styled.tr`
    margin: 4px;
    padding-top: -4px;
    padding-bottom: 8px;
`;

const ItemName = styled.td`
    text-align: right;
    padding-right: 8px;
`;

const ItemValue = styled.td`
    text-align: left;
    padding-left: 8px;
`;

const SliderLabel = styled.div`
    text-align: center;
    margin-top: -8px;
    font-size: 12;
`;

const ItemExplanation = styled.td`
    text-align: center;
    font-size: 12px;
    font-style: italic;
    padding-top: -2px;
    padding-bottom: 8px;
`;

const SettingsSlider = styled.input`
    width: 100%;
    height: 20px;
`;

const DisplayNameInput = styled.input`
    width: 100%;
    height: 20px;
`;

const InvalidDisplayNameHint = styled.span`
    font-size: 12px;
    color: red;
    margin-top: 4px;
`;

const SaveButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary_dark};
    border: none;
    border-radius: 6px;
    color: white;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 12px;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
    }

    &:disabled {
        background-color: ${(props) => props.theme.colors.primary_dull};
    }
`;

const BackButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary_very_light};
    border: none;
    border-radius: 6px;
    color: black;
    padding: 8px 35px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_light};
    }
`;

const AvatarContainer = styled.div`
    width: 48px;
    height: 48px;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
`;

const AvatarImage = styled.img`
    width: 40px;
    height: 40px;
`;

const AvatarControls = styled.div`
    display: flex;
    align-items: center;
`;

const AvatarButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
    border: none;
    border-radius: 4px;
    color: ${(props) => props.theme.colors.primary};
    padding: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: ${(props) => props.theme.colors.primary_light};
    }

    svg {
        width: 24px;
        height: 24px;
        fill: currentColor;
    }
`;

type UserSettingsPageProps = {
    apiClient: ApiClient;
    user: DmUser;
    updateUser: (user: DmUser) => void;
};

export function UserSettingsPage(props: UserSettingsPageProps): JSX.Element {
    const { apiClient, user, updateUser } = props;

    const [selectedVolume, setSelectedVolume] = React.useState(user.user_settings?.volume);
    const [selectedSpeed, setSelectedSpeed] = React.useState(user.user_settings?.speed);
    const [selectedDisplayName, setSelectedDisplayName] = React.useState(user.display_name);
    const [selectedAvatar, setSelectedAvatar] = React.useState(user.avatar);
    const [dirty, setDirty] = React.useState<"dirty" | "saved" | "unchanged">("unchanged");

    const navigate = useNavigate();

    useEffect(() => {
        setSelectedVolume(user.user_settings?.volume);
        setSelectedSpeed(user.user_settings?.speed);
        setSelectedDisplayName(user.display_name);
        setSelectedAvatar(user.avatar);
    }, [user]);
    if (!user.user_settings || selectedVolume === undefined || selectedSpeed === undefined) {
        return (
            <>
                <NavBar />
                <div>Loading...</div>
            </>
        );
    }

    const isDisplayNameValid = selectedDisplayName.length >= 3 && selectedDisplayName.length <= 16;
    const isSaveButtonDisabled = !isDisplayNameValid || dirty !== "dirty";

    return (
        <>
            <NavBar />

            <ParentDiv>
                <SettingsArea>
                    <SettingsTopStrip>
                        <BackButton onClick={() => navigate(-1)}>← Return</BackButton>
                    </SettingsTopStrip>
                    <SettingsTable>
                        <tbody>
                            <SettingsItemRow>
                                <ItemName>Avatar:</ItemName>
                                <ItemValue>
                                    <AvatarControls>
                                        <AvatarButton
                                            onClick={() => {
                                                const newAvatarId = getPreviousAvatarId(selectedAvatar);
                                                setSelectedAvatar(newAvatarId);
                                                setDirty("dirty");
                                            }}
                                        >
                                            <ArrowLeft />
                                        </AvatarButton>
                                        <AvatarContainer>
                                            <AvatarImage src={getAvatarUrl("", selectedAvatar)} alt="User Avatar" />
                                        </AvatarContainer>
                                        <AvatarButton
                                            onClick={() => {
                                                const newAvatarId = getNextAvatarId(selectedAvatar);
                                                setSelectedAvatar(newAvatarId);
                                                setDirty("dirty");
                                            }}
                                        >
                                            <ArrowRight />
                                        </AvatarButton>
                                    </AvatarControls>
                                </ItemValue>
                            </SettingsItemRow>
                            <SettingsExplanationRow>
                                <ItemExplanation colSpan={2}>A simple image that represents you.</ItemExplanation>
                            </SettingsExplanationRow>
                            <SettingsItemRow>
                                <ItemName>Email Address:</ItemName>
                                <ItemValue>{user.email}</ItemValue>
                            </SettingsItemRow>
                            <SettingsExplanationRow>
                                <ItemExplanation colSpan={2}>Private. Your way to sign in.</ItemExplanation>
                            </SettingsExplanationRow>
                            <SettingsItemRow>
                                <ItemName>Display Name:</ItemName>
                                <ItemValue>
                                    <DisplayNameInput
                                        value={selectedDisplayName}
                                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                            setSelectedDisplayName(event.currentTarget.value);
                                            setDirty("dirty");
                                        }}
                                    />
                                </ItemValue>
                            </SettingsItemRow>
                            <SettingsExplanationRow>
                                <ItemExplanation colSpan={2}>
                                    {isDisplayNameValid ? (
                                        "How you appear to others and yourself."
                                    ) : (
                                        <InvalidDisplayNameHint>Must be between 3 and 16 characters.</InvalidDisplayNameHint>
                                    )}
                                </ItemExplanation>
                            </SettingsExplanationRow>
                            <SettingsItemRow>
                                <ItemName>Max gameplay speed:</ItemName>
                                <ItemValue>
                                    <SettingsSlider
                                        type="range"
                                        min="0"
                                        max="4"
                                        value={selectedSpeed}
                                        className="slider"
                                        id="speedSlider"
                                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                            const newValue = parseInt(event.currentTarget.value) as 0 | 1 | 2 | 3 | 4;
                                            setSelectedSpeed(newValue);
                                            setDirty("dirty");
                                        }}
                                    ></SettingsSlider>
                                    <SliderLabel>{getUserSettingSpeedLabel(selectedSpeed)}</SliderLabel>
                                </ItemValue>
                            </SettingsItemRow>
                            <SettingsExplanationRow>
                                <ItemExplanation colSpan={2}>
                                    How briskly to present moves that were made in quick succession.
                                </ItemExplanation>
                            </SettingsExplanationRow>
                            <SettingsItemRow>
                                <ItemName>Sound effects volume:</ItemName>
                                <ItemValue>
                                    <SettingsSlider
                                        type="range"
                                        min="0"
                                        max="4"
                                        value={selectedVolume}
                                        className="slider"
                                        id="volumeSlider"
                                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                            const newValue = parseInt(event.currentTarget.value) as 0 | 1 | 2 | 3 | 4;
                                            setSelectedVolume(newValue);
                                            setDirty("dirty");
                                        }}
                                    ></SettingsSlider>
                                    <SliderLabel>{getUserSettingVolumeLabel(selectedVolume)}</SliderLabel>
                                </ItemValue>
                            </SettingsItemRow>
                            <SettingsExplanationRow>
                                <ItemExplanation colSpan={2}>How loud to play sounds indicating that a move was made.</ItemExplanation>
                            </SettingsExplanationRow>
                            <SettingsItemRow>
                                <td colSpan={2}>
                                    <SaveButton
                                        disabled={isSaveButtonDisabled}
                                        onClick={() => {
                                            apiClient
                                                .updateSettings(selectedDisplayName, selectedAvatar, {
                                                    speed: selectedSpeed,
                                                    volume: selectedVolume,
                                                })
                                                .then((response) => {
                                                    if (response instanceof Error) {
                                                        console.error(response);
                                                        return;
                                                    }
                                                    setDirty("saved");
                                                    updateUser(response.user);
                                                });
                                        }}
                                    >
                                        {dirty !== "saved" ? "Save Changes" : "Saved ✓"}
                                    </SaveButton>
                                </td>
                            </SettingsItemRow>
                        </tbody>
                    </SettingsTable>
                </SettingsArea>
            </ParentDiv>
        </>
    );
}
