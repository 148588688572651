import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { GameStatus } from "../../client/server-types-python";
import { InterfaceButton } from "./InterfaceButton";

const DropdownButton = styled(InterfaceButton)`
    position: relative;
    display: inline-block;
    margin: 0px 0px 0px 6px;
    border-radius: 6px;
`;

const DropdownMenuParent = styled.div`
    position: relative;
    display: inline-block;
`;

const GameDropdownDiv = styled.div`
    display: block;
    position: absolute;
    right: 0px;
    top: 100%;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    button {
        display: block;
        width: 100%;
        padding: 12px 16px;
        text-decoration: none;
        background-color: ${(props) => props.theme.colors.primary_hinted_white};
        color: ${(props) => props.theme.colors.black_text};
        text-align: left;
        border: none;
    }

    button:hover {
        background-color: ${(props) => props.theme.colors.light};
    }
`;

const DropdownItemButton = styled.button`
    display: block;
    width: 100%;
    padding: 12px 16px;
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
    color: ${(props) => props.theme.colors.black_text};
    text-align: left;
    border: none;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_very_light};
    }
`;

interface GameDropdownProps {
    leaveTable: () => void;
    requestAbort: () => void;
    gameStatus: GameStatus;
}

export const GameDropdown: React.FC<GameDropdownProps> = ({ leaveTable, requestAbort, gameStatus }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <DropdownMenuParent ref={dropdownRef}>
            <DropdownButton onClick={toggleDropdown}>☰</DropdownButton>
            {dropdownOpen && (
                <GameDropdownDiv>
                    <DropdownItemButton onClick={leaveTable}>Leave table</DropdownItemButton>
                    <DropdownItemButton disabled={gameStatus === GameStatus.COMPLETE} onClick={requestAbort}>
                        End game
                    </DropdownItemButton>
                </GameDropdownDiv>
            )}
        </DropdownMenuParent>
    );
};
