import styled from "styled-components";
import { DmTable, DmHistoryEntry } from "../../client/server-types-python";
import { ReplayState } from "../../model/ReplayState";
import React from "react";

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
`;

export const ModalContent = styled.div`
    position: relative;
    width: 300px;
    min-height: 100px;
    padding: 20px;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    display: flex;
    flex-direction: column;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    padding: 10px;
    margin: -10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const ScoreTable = styled.table`
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
`;

export const ScoreTableRow = styled.tr`
    background-color: ${(props) => props.theme.colors.white_text};
`;

export const ScoreTableHeader = styled.th`
    text-align: center;
    background-color: ${(props) => props.theme.colors.score_header};
    color: white;
    font-size: 16px;
    padding: 10px;
    width: 60px;
`;

export const ScoreTableHeaderHandNumber = styled(ScoreTableHeader)`
    width: 30px;
`;

export const ScoreTableCell = styled.td`
    text-align: right;
    width: 60px;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.colors.primary_light};
    border-left: none;
    &:last-child {
        border-right: none;
    }
    border-top: none;
`;

export const ScoreTableBottomRowCell = styled(ScoreTableCell)`
    border-bottom: none;
    font-weight: bold;
`;

export const ScoreTableBottomRowHandNumberCell = styled(ScoreTableBottomRowCell)`
    width: 30px;
`;

export const DoubleScoreTableCell = styled(ScoreTableCell)`
    text-align: center;
`;

export const HandNumberCell = styled(ScoreTableCell)`
    width: 30px;
`;

export const ReplayHandButton = styled.button`
    background-color: ${(props) => props.theme.colors.alternate};
    color: white;
    border: none;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 3px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 24px;
    border-radius: 6px;
    width: 36px;
    height: 36px;
`;

export type ScoreModalProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    table: DmTable;
    replayState: ReplayState;
    setReplayState: (replayState: ReplayState) => void;
    handHistoryCache: (DmHistoryEntry[] | null)[];
    playerIsNs: boolean;
    gameIsOver: boolean;
    nextGameFunc: () => void;
};

// TODO: add ability to click buttons to replay past hands.
// TODO: Add ability to use replay state to show the score status of the past.
export const ScoreModal = ({
    isOpen,
    setIsOpen,
    table,
    replayState,
    setReplayState,
    handHistoryCache,
    playerIsNs,
    gameIsOver,
    nextGameFunc,
}: ScoreModalProps): JSX.Element => {
    if (!isOpen || !table.game) {
        return <></>;
    }
    const handEntries = [];
    let nsTotalScore = 0;
    let ewTotalScore = 0;
    for (const hand of table.game?.game_hands ?? []) {
        const playState = hand.play_state;
        if (playState.is_hand_over) {
            nsTotalScore += playState.ns_score;
            ewTotalScore += playState.ew_score;
            handEntries.push(
                <ScoreTableRow key={hand.index}>
                    <HandNumberCell>{hand.index + 1}</HandNumberCell>
                    <ScoreTableCell>{playerIsNs ? playState.ns_score : playState.ew_score}</ScoreTableCell>
                    <ScoreTableCell>{playerIsNs ? playState.ew_score : playState.ns_score}</ScoreTableCell>
                    <ScoreTableCell>
                        <ReplayHandButton
                            onClick={() => {
                                setReplayState({ replayModeEnabled: true, replayHandCursor: hand.index, replayMoveCursor: 0 });
                                setIsOpen(false);
                            }}
                        >
                            👁
                        </ReplayHandButton>
                    </ScoreTableCell>
                </ScoreTableRow>
            );
        } else {
            handEntries.push(
                <ScoreTableRow key={hand.index}>
                    <HandNumberCell>{hand.index + 1}</HandNumberCell>
                    <DoubleScoreTableCell colSpan={2}>In progress...</DoubleScoreTableCell>
                    <ScoreTableCell>
                        <ReplayHandButton
                            onClick={() => {
                                setReplayState({ replayModeEnabled: false, replayHandCursor: hand.index, replayMoveCursor: 0 });
                                setIsOpen(false);
                            }}
                        >
                            ⇨
                        </ReplayHandButton>
                    </ScoreTableCell>
                </ScoreTableRow>
            );
        }
    }
    return (
        <ModalOverlay onClick={() => setIsOpen(false)}>
            <ModalContent
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                }}
            >
                <CloseButton onClick={() => setIsOpen(false)}>X</CloseButton>
                <ScoreTable>
                    <tbody>
                        <ScoreTableRow>
                            <ScoreTableHeaderHandNumber>Hand</ScoreTableHeaderHandNumber>
                            <ScoreTableHeader>Us</ScoreTableHeader>
                            <ScoreTableHeader>Them</ScoreTableHeader>
                            <ScoreTableHeader>Replay</ScoreTableHeader>
                        </ScoreTableRow>
                        {handEntries}
                        <ScoreTableRow>
                            <ScoreTableBottomRowHandNumberCell>Total</ScoreTableBottomRowHandNumberCell>
                            <ScoreTableBottomRowCell>{playerIsNs ? nsTotalScore : ewTotalScore}</ScoreTableBottomRowCell>
                            <ScoreTableBottomRowCell>{playerIsNs ? ewTotalScore : nsTotalScore}</ScoreTableBottomRowCell>
                            <ScoreTableBottomRowCell></ScoreTableBottomRowCell>
                        </ScoreTableRow>
                    </tbody>
                </ScoreTable>
                {gameIsOver && (
                    <button className="nextHandButton easyButton" onClick={nextGameFunc}>
                        Next Game
                    </button>
                )}
            </ModalContent>
        </ModalOverlay>
    );
};
