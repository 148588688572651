import React from "react";
import styled from "styled-components";
import theme from "../../theme";

interface HandNumberDisplayProps {
    handNumber: number;
}

const StyledHandNumber = styled.div`
    width: 48px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.white_text};
    background: linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.primary_light});
    border-radius: 6px;
    position: relative;
    overflow: hidden;
`;

const HandText = styled.span`
    font-size: 9px;
    margin-bottom: -4px;
    margin-top: 1px;
`;

const Number = styled.span`
    font-size: 18px;
    font-weight: bold;
`;

const HandNumberDisplay: React.FC<HandNumberDisplayProps> = ({ handNumber }) => {
    return (
        <StyledHandNumber>
            <HandText>HAND</HandText>
            <Number>#{handNumber}</Number>
        </StyledHandNumber>
    );
};

export default HandNumberDisplay;
