import { useState } from "react";
import { ApiClient } from "../client/ApiClient";
import styled from "styled-components";
import butterfly from "../assets/butterfly-black.svg";
import { DmAuthResponse } from "../client/server-types-python";
import { getRandomAvatarId } from "../misc/Avatars";
import theme from "../theme";
import { AxiosError } from "axios";

const LoginBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    background-color: ${theme.colors.primary_hinted_white};
    padding: 30px;
    margin: 20px auto;
    border-radius: 8px;
`;

const LoginForm = styled.div`
    width: 100%;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const FieldLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
`;

const InputField = styled.input`
    width: 260px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid ${theme.colors.primary_light};
    border-radius: 4px;
`;

const Checkbox = styled.input`
    margin: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const SubmitButton = styled.button`
    background-color: ${theme.colors.primary_dark};
    border: none;
    border-radius: 6px;
    color: white;
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;

    &:hover {
        background-color: ${theme.colors.primary};
    }

    &:disabled {
        background-color: ${theme.colors.primary_dull};
        cursor: default;
    }
`;

const ErrorMessage = styled.div`
    color: ${theme.colors.action_negative};
    font-size: 14px;
    margin-top: 10px;
`;

const WelcomeMessage = styled.h2`
    font-size: 16px;
    font-style: italic;
    margin-bottom: 20px;
    text-align: center;
`;

const FieldExplanation = styled.div`
    font-size: 12px;
    margin-top: 5px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 10px 0;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0;
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 8px;
    line-height: 20px;
`;

export function Login(props: {
    apiClient: ApiClient;
    setLoggedInCallback: (dmAuthResponse: DmAuthResponse) => void;
    resetPasswordPageData: { email: string; token: string } | null;
}): JSX.Element {
    const isResetPasswordPage = props.resetPasswordPageData !== null;

    const [email, setEmail] = useState(isResetPasswordPage ? props.resetPasswordPageData!.email : "");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [registerMode, setRegisterMode] = useState(false);
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
    const [postResetPasswordMode, setPostResetPasswordMode] = useState(false);
    const [lastSubmitError, setLastSubmitError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const welcomeMessage = isResetPasswordPage ? "Welcome back. You can reset your password below." : "Log in to use the site.";

    const isEmailValid = email.length >= 1;
    const isPasswordValid = password.length >= 1;
    const isDisplayNameValid = displayName.length >= 3 && displayName.length <= 16;
    const errorOnSubmit = (registerMode && !isDisplayNameValid) || !isEmailValid || (!forgotPasswordMode && !isPasswordValid);

    const changeRegisterMode = (newMode: boolean) => {
        setRegisterMode(newMode);
        setForgotPasswordMode(false);
        setLastSubmitError(null);
    };

    const changeForgotPasswordMode = (newMode: boolean) => {
        setForgotPasswordMode(newMode);
        setRegisterMode(false);
        setLastSubmitError(null);
    };

    const handleErrorResponse = (error: Error) => {
        if (error instanceof AxiosError) {
            if (error.code === "ERR_NETWORK") {
                setLastSubmitError("The server is currently unavailable. Please try again later.");
            } else {
                setLastSubmitError(error.response?.data.msg);
            }
        } else {
            console.error(error);
            setLastSubmitError("An unexpected error occurred. Please try again later.");
        }
    };

    return (
        <LoginBox>
            {postResetPasswordMode ? (
                <div>
                    An email is being sent to {email} with a link to reset your password. This normally takes less than a minute. Check your
                    spam folder if necessary.
                </div>
            ) : (
                <LoginForm>
                    <WelcomeMessage>{welcomeMessage}</WelcomeMessage>
                    <img src={butterfly} alt="butterfly" height="30px" style={{ display: "block", margin: "0 auto 20px" }} />

                    <FormGroup>
                        <FieldLabel htmlFor="email">Email:</FieldLabel>
                        <InputField
                            disabled={isResetPasswordPage}
                            type="text"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    {!forgotPasswordMode && (
                        <FormGroup>
                            <FieldLabel htmlFor="password">{isResetPasswordPage ? "New " : ""} Password:</FieldLabel>
                            <InputField
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            />
                        </FormGroup>
                    )}
                    {!isResetPasswordPage && (
                        <FormGroup>
                            <CheckboxContainer>
                                {!forgotPasswordMode && (
                                    <CheckboxWrapper>
                                        <Checkbox
                                            type="checkbox"
                                            id="register"
                                            checked={registerMode}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeRegisterMode(e.target.checked)}
                                        />
                                        <CheckboxLabel htmlFor="register">Create a new account</CheckboxLabel>
                                    </CheckboxWrapper>
                                )}
                                {!registerMode && (
                                    <CheckboxWrapper>
                                        <Checkbox
                                            type="checkbox"
                                            id="forgotPassword"
                                            checked={forgotPasswordMode}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                changeForgotPasswordMode(e.target.checked)
                                            }
                                        />
                                        <CheckboxLabel htmlFor="forgotPassword">Forgot my password</CheckboxLabel>
                                    </CheckboxWrapper>
                                )}
                            </CheckboxContainer>
                        </FormGroup>
                    )}
                    {registerMode && !isResetPasswordPage && (
                        <FormGroup>
                            <FieldLabel htmlFor="display_name">Display Name:</FieldLabel>
                            <InputField
                                type="text"
                                id="display_name"
                                name="display_name"
                                value={displayName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDisplayName(e.target.value)}
                            />
                            <FieldExplanation>
                                <span style={{ color: isDisplayNameValid ? "green" : "red" }}>Must be between 3 and 16 characters</span>
                            </FieldExplanation>
                        </FormGroup>
                    )}
                    <SubmitButton
                        disabled={isSubmitting}
                        onClick={() => {
                            if (errorOnSubmit) {
                                if (!isEmailValid) {
                                    setLastSubmitError("Please enter a valid email address.");
                                } else if (!isPasswordValid && !forgotPasswordMode) {
                                    setLastSubmitError("Please enter a password.");
                                } else if (!isDisplayNameValid && registerMode) {
                                    if (displayName.length < 1) {
                                        setLastSubmitError("Please enter a display name.");
                                    } else {
                                        setLastSubmitError("Display name must be between 3 and 16 characters.");
                                    }
                                }
                                return;
                            }
                            if (isSubmitting) return;
                            setIsSubmitting(true);
                            setLastSubmitError(null);

                            if (isResetPasswordPage) {
                                const changePasswordResponse = props.apiClient.changePasswordViaToken(
                                    email,
                                    password,
                                    props.resetPasswordPageData!.token
                                );
                                changePasswordResponse.then((response) => {
                                    setIsSubmitting(false);
                                    if (response instanceof Error) {
                                        console.error(response);
                                        handleErrorResponse(response);
                                    } else {
                                        props.setLoggedInCallback(response);
                                    }
                                });
                            } else if (forgotPasswordMode && !registerMode) {
                                const resetPasswordResponse = props.apiClient.resetPassword(email);
                                resetPasswordResponse.then((response) => {
                                    setIsSubmitting(false);
                                    if (response instanceof Error) {
                                        console.error(response);
                                        handleErrorResponse(response);
                                    } else {
                                        setForgotPasswordMode(false);
                                        setPostResetPasswordMode(true);
                                    }
                                });
                            } else {
                                const responsePromise = registerMode
                                    ? props.apiClient.register(email, password, displayName, getRandomAvatarId())
                                    : props.apiClient.login(email, password);
                                responsePromise.then((response) => {
                                    setIsSubmitting(false);
                                    if (response instanceof Error) {
                                        handleErrorResponse(response);
                                    } else {
                                        props.setLoggedInCallback(response);
                                    }
                                });
                            }
                        }}
                    >
                        {isSubmitting
                            ? "Please wait..."
                            : isResetPasswordPage
                            ? "Update Password and log in"
                            : registerMode
                            ? "Register"
                            : forgotPasswordMode
                            ? "Send me an email"
                            : "Log in"}
                    </SubmitButton>
                    {lastSubmitError && <ErrorMessage>{lastSubmitError}</ErrorMessage>}
                </LoginForm>
            )}
        </LoginBox>
    );
}
