import styled from "styled-components";

export const InterfaceButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary_dark};
    border: none;
    border-radius: 6px;
    color: ${(props) => props.theme.colors.white_text};
    padding: 0px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 3px;
    height: 36px;
    line-height: 1;
    vertical-align: middle;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_darker};
    }

    &:disabled {
        background-color: ${(props) => props.theme.colors.primary_dull};
        color: ${(props) => props.theme.colors.white_text_dull};
        pointer-events: none;
    }
`;
