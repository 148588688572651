import styled from "styled-components";
import { NavBar } from "../components/NavBar";

const ParentDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 16px;
    padding: 30px 50px 30px 50px;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    justify-content: center;
    align-content: center;
    text-align: left;
    margin: 8px;
`;

const Header = styled.h2`
    font-size: 24px;
    margin-block-end: 0;
    margin-block-start: 12px;
`;

const BigHeader = styled.h1`
    font-size: 36px;
    margin-block-end: 0;
    margin-block-start: 12px;
`;

export function AboutPage(): JSX.Element {
    return (
        <>
            <NavBar />

            <ParentDiv>
                <MainDiv>
                    <Section>
                        <BigHeader>About</BigHeader>
                    </Section>
                    <Section>
                        <p>
                            <b>tichuengine.com</b> is a hobby project by me, Sean McCarthy. I'm aiming to provide a smooth and convenient
                            way to play Tichu digitally. Features include one-click combination plays, game replays, unlimited undo,
                            optional duplicate scoring, and AI opponents.
                        </p>
                        <p>
                            Tichu is one of my favorite games and is best played in person with real cards. But sometimes, playing in person
                            is not an option.
                        </p>
                        <p>
                            This website is still in development, and is <b>very much a work in progress</b>. Right now, it is only publicly
                            accessible for testing purposes, so please don't advertise it or rely on it.
                        </p>
                    </Section>
                    <Section>
                        <Header>Rules differences from standard Tichu</Header>
                    </Section>
                    <Section>
                        <p>
                            The game available here has slightly tweaked rules compared to Tichu. This is due to my belief that certain
                            aspects of Tichu work well in person but translate clumsily to the digital realm: specifically, the actions that
                            you can take out of turn.
                        </p>
                        <p>
                            Here, bombing and calling Tichu out of turn are no longer possible. This allows gameplay to flow more smoothly
                            and without worrying about reflexes.
                        </p>
                        <p>
                            These changes make for a slightly simpler game, and I don't consider them to be gameplay improvements. I see
                            them as compromises to make the interface friendlier that I think are worth the cost in depth.
                        </p>
                    </Section>
                    <Section>
                        <Header>Credit</Header>
                    </Section>
                    <Section>
                        <p>
                            <a href="https://boardgamegeek.com/boardgame/215/tichu">Tichu</a> is a game by Urs Hostettler and{" "}
                            <a href="https://fatamorgana.ch/fatamorgana/tichu">Fata Morgana</a>, and it's made available here with their
                            permission.
                        </p>
                        <p>Site design and programming by Sean McCarthy.</p>
                        <p>
                            The user avatar images and some game icons are sourced from <a href="https://game-icons.net/">Game-icons.net</a>{" "}
                            and were created by various artists: Lorc, Delapouite, John Colburn, Felbrigg, John Redman, Carl Olsen, Sbed,
                            PriorBlue, Willdabeast, Viscious Speed, Lord Berandas, Irongamer, HeavenlyDog, Lucas, Faithtoken, Skoll, Andy
                            Meneely, Cathelineau, Kier Heyl, Aussiesim, Sparker, Zeromancer, Rihlsul, Quoting, Guard13007, DarkZaitzev,
                            SpencerDub, GeneralAce135, Zajkonur, Catsu, Starseeker, Pepijn Poolman, Pierre Leducq, and Caro Asercion.
                        </p>
                        <p>
                            A few other game icons were licensed from <a href="https://thenounproject.com/">thenounproject.com</a>.
                        </p>
                    </Section>
                    <Section>
                        <Header>Bots (AI Opponents)</Header>
                    </Section>
                    <Section>
                        <p>
                            The bots are a complex work in progress. To make their decisions, they generate 10 different random permutations
                            of possible hand distributions for the other players. (This process is still rudimentary, and doesn't even take
                            into account known cards from those traded at the start of the hand.) Then for each of those possible
                            situations, they determine expected actions and the value of the resulting states using neural networks
                            (described below). Finally, they average the neural network outputs over the randomly generated situations to
                            get a final prediction of the best action to take.
                        </p>
                        <p>
                            To train the neural networks and in some in-game situations, I use techniques similar to AlphaZero. The basic
                            idea is to have a neural-network-assisted tree search. Tree search means that the bot tries to calculate out "if
                            I do this, the opponents will do that, and then I can do that, and then..." as deep as possible. These bots use
                            a type of tree search called Monte Carlo Tree Search (MCTS), which uses the heuristic of how promising a line of
                            play is to help decide which lines of play to go deeper down.
                        </p>
                        <p>
                            The tree search is assisted by neural networks in two ways. First, the "policy" network looks at a given
                            position and spits out weights for the different legal actions that represent what it thinks is the probability
                            that a perfect player would pick those respective actions. This can help inform the tree search, letting us
                            focus more on moves that are more likely to be good. Second, the "value" network can spit out rough guesses at
                            the value of a position, which we use in lieu of fully exploring a branch of the tree, as a sort of rough
                            estimate of how the game would turn out if we continued down that branch. This replaces the default heuristic in
                            MCTS of "what's the score if I play out the rest of the hand randomly".
                        </p>
                        <p>
                            The cool part is how we train these networks starting from scratch. We start by having the MCTS agents play a
                            bunch of games against each other, and then use those games as training data for the networks. This gives us
                            some minimally competent networks (they're able to predict mediocre choices made with some lookahead), and we
                            can use them to enhance the MCTS at the margin in order to generate better-played games. Those games feed back
                            into network training, and so on.
                        </p>
                        <p>
                            The current bots use raw neural network outputs to determine moves in most situations. They use the MCTS system
                            (outside of training) only when the remaining cards are known (i.e. when only one opponent is left in the hand).
                        </p>
                    </Section>
                </MainDiv>
            </ParentDiv>
        </>
    );
}
